import { DehydratedState } from '@tanstack/react-query';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';

import { useCustomRouter } from '$hooks/client';
import { AuthStatuses, useAuth } from '$src/hooks/auth';
import { useLoadingAtom } from '$src/hooks/state/useLoadingAtom';
import { FormQueryParams } from '$src/types';

// @ts-expect-error nextjs conflict types
export const getServerSideProps: GetServerSideProps<
  Partial<FormQueryParams> & { dehydratedState?: DehydratedState },
  FormQueryParams
> = async ({ locale = 'fr' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'fr', ['common'])),
    },
  };
};

/**
 * This index page is used like a router, redirecting to the correct page to display
 */
const CheckoutForm: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = () => {
  const router = useCustomRouter();
  const { authState } = useAuth() ?? {};
  const { onLoadingChange } = useLoadingAtom();

  useEffect(() => {
    if (authState === AuthStatuses.PENDING) return;
    router.push(`/${authState}`);
  }, [authState, router]);

  useEffect(() => {
    onLoadingChange(authState === AuthStatuses.PENDING);
  }, [onLoadingChange, authState]);

  return <></>;
};

export default CheckoutForm;
